 /**
  * 
  * This is an helper functions that let you login, check if user is login, and register user
  */
 
  import {urllink} from "../constants" 
 
  


const Mydgb = (puk) => {

  
  let message = {}
      let uri = urllink.myfarms + "/?puk=" + puk;
      
       
      const requestOptions = {
          method: 'GET',
          headers: {
              'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
           
      };
     
      
       
      const result = fetch(uri, requestOptions)
      .then( async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
               message = { 
                    error: data.error,
                    msg: (data && data.message) || response.status,
                    logout: false,
                    puk: (data && data.puk) || puk
                  
                    
                }
              return message;
              
          }
  
          
          message = { 
            error: data.error,
            msg: data.message,
            logout: data.logout,
            puk: data.puk,
            farmList: data.farm_list
        }

        return message;
              
      })
      .catch(error => {
           message = { 
                  error: true,
                  msg: "Network fail. Please try again.",
                  puk: puk

                  
              }
          return message;
  
      });
  
  
  return result;
}

 

 
 
const  CreateDgt = (dgtAmount, userEmail) => { 
   
  let uri = urllink.createDgt;
   
   
  const requestOptions = {
      method: 'POST',
      headers: {
          'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
      body: JSON.stringify({ amount: dgtAmount, user_id:userEmail})
  };
  
  const result = fetch(uri, requestOptions)
  .then( async response => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson && await response.json();
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
           
          return data;
          
      }

      
      return data;
   
          
  })
  .catch(error => {
       return error;
  });


return result;

}

const  Claim = () => {}

const  sendRequest = (data) => {

 
  const requestOptions = {
      method: 'POST',
      headers: {
          'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(data)
  };
 
  const result = fetch(data.uri, requestOptions)
                .then( async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    // check for error response
                      if (!response.ok) {
                          // get error message from body or default to response status
                          
                          return data;
                          
                      }
                        return data;
                    
                            
                    })
                    .catch(error => {
                        return error;
                    });


    return result;


}




   
export const CbtApi = {
  
     
    CreateDgt,
    Claim,
    
    sendRequest
     
  }

 

 

  



   