import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate} from "react-router-dom";
import { ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  
import ConnectWallet from '../Account/ConnectWallet';


var numeral = require('numeral');



const StakeBasic = () => {

  
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context); 
  //const { switchNetwork, chainId } = useChain();
  
  
  const userAddress = state.walletAddress;
   
  const [metToStake, setMetToStake] = useState(0); 
  
  const [metToGet, setMetToGet] = useState(0);
   
  const[ ErrorMessage, setErrorMessage] = useState(""); 
  const [toggleAlert, setToggleAlert] = useState(false); 
  
  const [showButton, setShowButton] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [serverInfo, setServerInfo] = useState("");  
  const { account, provider } = useWeb3React();  
  const [walletIsConnected, setWalletIsConnected] = useState(false);


  useEffect(() => {

      document.title = `Swap`;  
      

      if(state.isLoggedIn){  setWalletIsConnected(true); }

       
  },[state.isLoggedIn]);



function calculateSell(inputAmount){
setToggleAlert(false);

if(inputAmount >= 1){

  setMetToStake(inputAmount)
    setShowButton(true);
}else{
    setShowButton(false);
}

let met = (1.16667/100) * inputAmount * 30; // 1% daily multiply by amount to stake then multiply by total days
setMetToStake(inputAmount);
setMetToGet(met);

if(inputAmount > state.metBalance){

  // setShowButton(false);
  // setErrorMessage('Your met balance is low');
  // setToggleAlert(true);
  // return false;

}


setToggleAlert(false);


}

async function updateBalance(){

  let newBal = await MyFunction.balanceOf(userAddress);
  dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
  
}


async function stake(){


if(metToStake < 1000000){
  
    setErrorMessage('Minimum of 1,000,000 DGT is allowed');
    setToggleAlert(true);
    return false;

}

//Check dgt balance

if(state.metBalance < metToStake){
    setErrorMessage('Sorry, your balance is low');
    setToggleAlert(true);
    return false;
}


setToggleAlert(false); 
setIsProcessing(true);
setServerInfo('...connnecting to wallet')




let metAmount = ethers.utils.parseEther(metToStake);
let charge = "0.28";
  
const signer = provider.getSigner();
const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
const overrides = {value: ethers.utils.parseEther(charge)} 

dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

try {

  const tx = await contract.mined(metAmount,overrides); 
  // Waiting for the transaction to be mined
  const receipt = await tx.wait();
      
  if(receipt.status == 1){  
    setIsProcessing(false);
    dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 

    //login to database and save the stake
    

  }else{
     setErrorMessage('Sorry, unable to process at the moment');
      setToggleAlert(true);

      dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
      setIsProcessing(false);
  }


  updateBalance();   


}
catch(err){

setErrorMessage(" insufficient " + charge + " BNB gass fee. Fund your wallet and try again");


setToggleAlert(true);
setIsProcessing(false);

}

            
}



return (

  <>

<div className="roadmap-box right">
                <div className="time">Dogetagold Token Mining</div>
                <div className="content">
                <h5 className="title">35% Return Monthly</h5>
                <div className="text">

                    <div className="input-group mb-3">
                                            
                        <input type="number" className="form-control" placeholder="20" onChange={(e) => calculateSell(e.target.value)} />
                        
                    </div>
                    <div className='input-group mb-3'>
                        <p> 
                         DGT Balance: {state.metBalance}
                        </p>
                    </div>
                    <div className='input-group mb-3'>
                     
                      <p>
                        Total Return 35% 
                      </p>
                        
                        
                    </div>
                    <div className='input-group mb-3'>
                    
                        <p className='mute'>
                            You will receive { numeral(metToGet).format("0.0000") } DGT
                        </p>
                      
                        
                    </div>
                    <div className='input-group mb-3'>
                      <p className='mute'>
                            You will mine {numeral(metToStake).format("0.0000")} DGT
                      </p>
                    </div>
                  


                    <Myalert message={ErrorMessage} toggle={toggleAlert} />
                    {


                          walletIsConnected != true ? <>
                        
                          <ConnectWallet inviteBy='1' />
                        </>:<>
                   
                              {
                                    showButton == true ? <>

                                        {
                                            isProcessing == true ? <>

                                                    <Myalert message={serverInfo} toggle={true} />
                                                    
                                
                                            </>: <>
                                                <button className="fs-14 form-control" onClick={() => stake()}>MINE</button>
                                
                                            </>
                                        }
                                        
                                    </>: <> </>
                              } 
                          </>
                    }
                </div>
                </div>
              </div>
     
  </>
)



}


export default StakeBasic;
