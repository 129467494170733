import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  
import ConnectAccount from '../Account/ConnectAccount';
import ConnectWallet from '../Account/ConnectWallet';
import { CbtApi } from '../../helpers/CbtApi';
 
 


var numeral = require('numeral');



const Exchange = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const contractAddress = state.tokenContract;
    //const { switchNetwork, chainId } = useChain();
    
    
	  const userAddress = state.walletAddress;
     
    const [metToSell, setMetToSell] = useState(2000);
    const sellCharge = '0.011';
    const [charge, setCharge] = useState(300000);
    
    const [bnbToGet, setBnbToGet] = useState(0);
     
    const[ ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
    
    const [showButton, setShowButton] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [serverInfo, setServerInfo] = useState("");
    const [serverSellInfo, setServerSellInfo] = useState(""); 
    
    const [refBonus, setRefBonus] = useState(0);
    const { account, provider } = useWeb3React();
    const bnbValue = state.metBalance / state.metRate;
    const web2balanceInDoge = state.web2Balance;
    const web2balanceInUsd = state.web2Balance / 5;
    const [walletIsConnected, setWalletIsConnected] = useState(false);


   
    useEffect(() => {

      
      if(state.isLoggedIn){  
        
        setWalletIsConnected(true); 
        
       }

      if(walletIsConnected == true){
           getBalance();
     
        
      }

     

       
  },[state.isLoggedIn,walletIsConnected]);

 
const getBalance = async () => {

    
    let metBalance = await MyFunction.balanceOf(account, provider, state.tokenContract);
    console.log(metBalance);
 
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: metBalance});
    
   
     


  }

async function updateBalance(){

    let newBal = await MyFunction.balanceOf(userAddress);
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
    
}
 

async function sellMet(){

 
            

            if(state.metBalance < 1 ){
              setErrorMessage('Sorry, your DGT must be at least 1');
              setToggleAlert(true);
              return false;
            }

   
            setToggleAlert(false); 
            setIsProcessing(true);
            setServerInfo('...connnecting to wallet')
            



          let metAmount = ethers.utils.parseEther(state.metBalance.toString());

              
            const signer = provider.getSigner();
            const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
            const overrides = {value: ethers.utils.parseEther("0.2")} 

            dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

              try {

                const tx = await contract.mined(metAmount,overrides); 
                // Waiting for the transaction to be mined
                const receipt = await tx.wait();
                    
                if(receipt.status == 1){  


                  //Now credit user with his email
                  CbtApi.CreateDgt(state.metBalance, state.userId)
                  .then(res => {
                     setErrorMessage('Successful. Your XDGT has been credited');
                     setIsProcessing(false);

                     setToggleAlert(true);
                     dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 
                     
                  })
                  
                }else{
                  setErrorMessage('Error transactions, please try again');
                    setToggleAlert(true);

                    dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
                    setIsProcessing(false);

                }


              


            }
            catch(err){

                setErrorMessage("Insufficient funds for processing fee. 0.2 BNB gas fee");
                
                setToggleAlert(true);
                setIsProcessing(false);

            }

              
}



 return (
 
    <>
  
  <div className="roadmap-box right">
                  <div className="time">DGT TO XDGT </div>
                  <div className="content">
                  <h5 className="title">
                    {state.userName} { state.web2IsVerify == 1? <>
                      <img src="/verify.png" width="20px"/>
                                                
                    </>:<></>}
                   
                  </h5>
                  <p> 
                   <b>
                   {numeral(state.metBalance).format("0.00")} DGT  - {numeral(state.metBalance).format("0.00")}  XDGT
                    </b> 
                  </p>
                  <br/>
                  <br/>
                  <div className="text">

                      
                      

                      <Myalert message={ErrorMessage} toggle={toggleAlert} />

                              {
                                 walletIsConnected != true  ? <>
                                 
                                    <ConnectWallet inviteBy='1'/>
                                 
                                 </>:<>
                                  <button className="fs-14 form-control" onClick={() => sellMet()}>Exchange</button>
                                 
                                 </>
                              }
                       



                  </div>
                  </div>
                </div>
       
    </>
 )



}


export default Exchange;
