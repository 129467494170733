import React, { useState, useContext } from 'react'; 
import { Link } from "react-router-dom";
import { Context } from "../../Store"; 
import { Modal } from "react-bootstrap"; 
import ConnectWallet from '../Account/ConnectWallet';
import {  useParams } from "react-router-dom";

const Header = (props) => {
  const [state, dispatch] = useContext(Context);
   
let params = useParams();  
const ref = params.ref;


 return (
 
    <>
    
    <header id="header_main" className="header">
    <div className="container big">
      <div className="row">
        <div className="col-12">
          <div className="header__body">
            <div className="header__logo">
              <a href="https://dogetagold.com">
                Dogetagold
              </a>
            </div>
            <div className="header__right">
              <nav id="main-nav" className="main-nav">
                <ul id="menu-primary-menu" className="menu">
                <li className="menu-item ">
                    <a href="https://dogetagold.com">Home</a>
                    
                  </li>
                  
                  <li className="menu-item">
                    <a href='/mine'>
                      <span>Mine</span>
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href='/swap'>
                      <span>Swap</span>
                    </a>
                  </li> 
                  <li className="menu-item">
                     <a href="/exchange">Exchange</a>
                   </li>
                </ul>
              </nav>
              <div className="mobile-button">
                <span />
              </div>
            </div>
               
          </div>
        </div>
      </div>
    </div>
  </header>

       

       


 
    </>
 )



}


export default Header;
