import React, { useEffect, useState, useContext } from 'react'; 

import Header from '../components/private/Header';
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";  
import { Context } from '../Store';
import ACTIONS from "../constants/Actions";  
import TemplateLayout from '../components/TemplateLayout';
import { useWeb3React } from "@web3-react/core";
import { urllink } from '../constants';  
import SellMet from "../components/private/SellMet";
import BuyMet from '../components/private/BuyMet'; 



var numeral = require('numeral');



const SwapPage = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const contractAddress = state.tokenContract;
    //const { switchNetwork, chainId } = useChain();
    
    
	  const userAddress = state.walletAddress; 
     
    
    const [toggleAlert, setToggleAlert] = useState(false);  
    
    const [refBonus, setRefBonus] = useState(0);
    const { account, provider } = useWeb3React();
    const bnbValue = state.metBalance / state.metRate;


    useEffect(() => {

        document.title = `Swap`;  
         
         
    },[]);

  

 return (
 
    <>
  
      <TemplateLayout >



      <section className="roadmap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <h6 className="sub-heading" >
                  <span style={{display: "block"}}>  SWAP DOGETAGOLD TOKEN (DGT)</span>
                   

                  
                </h6>
                
                
                 
               

                <p>
                  {
                    state.metBalance > 0? <>
                      <span>Balance: </span><br/>
                      <span>DGT: {numeral(state.metBalance).format("0.00")} ~ {numeral(bnbValue).format("0.0000000000")} BNB</span>
                    </>:<></>
                  }
                   
                  <br/><br/>
                  <span>Wallet ID: {account}</span>
                  <br/>
                  
                </p>

               
              </div>
              <div className="roadmap__main">
                 <BuyMet />
                 <SellMet />
                
                
              
                
                
                
              </div>
            </div>
          </div>
        </div>
      </section>


         
   

      </TemplateLayout>
      
       
    </>
 )



}


export default SwapPage;
