import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  
import ConnectWallet from '../Account/ConnectWallet';


var numeral = require('numeral');



const SellMet = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const contractAddress = state.tokenContract;
    //const { switchNetwork, chainId } = useChain();
    
    
	  const userAddress = state.walletAddress;
     
    const [metToSell, setMetToSell] = useState(2000);
    const [sellCharge, setSellCharge]=  useState(0);
    
    const [bnbToGet, setBnbToGet] = useState(0);
     
    const[ ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
    
    const [showButton, setShowButton] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [serverInfo, setServerInfo] = useState("");
    const [serverSellInfo, setServerSellInfo] = useState(""); 
    
    const [refBonus, setRefBonus] = useState(0);
    const { account, provider } = useWeb3React();
    const bnbValue = state.metBalance / state.metRate;
    const [walletIsConnected, setWalletIsConnected] = useState(false);
     


    useEffect(() => {

      
      if(state.isLoggedIn){  setWalletIsConnected(true); }

       
  },[state.isLoggedIn]);

     
 

function calculateSell(inputAmount){
  

  if(inputAmount >= 1){

    setMetToSell(inputAmount)
      setShowButton(true);
  }else{
      setShowButton(false);
  }
 
  let bnb = (1/state.metRate) * inputAmount;
  setMetToSell(inputAmount);
  setBnbToGet(bnb);
  setSellCharge(bnb * 0.05);


  setToggleAlert(false);


}

async function updateBalance(){

    let newBal = await MyFunction.balanceOf(userAddress);
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
    
}
 

async function sellMet(){

 
  if(metToSell < 1000000){
    
      setErrorMessage('Minimum of 1,000,000.00 DGT is allowed');
      setToggleAlert(true);
      return false;

  }

  setToggleAlert(false); 
  setIsProcessing(true);
  setServerInfo('...connnecting to wallet')
 



 let metAmount = ethers.utils.parseEther(metToSell);

  let charge = sellCharge.toString();
  const signer = provider.getSigner();
  const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
  const overrides = {value: ethers.utils.parseEther(charge)} 
  let bnbGet = ethers.utils.parseEther(numeral(bnbToGet).format("0.0000"));

  dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

  try {

    
    //const tx = await contract.sell(account, metAmount, overrides); 
    const tx = await contract.convert(metAmount, overrides);
    //const tx = await contract.withdrawBnb(bnbGet,account); 
    // Waiting for the transaction to be mined
    const receipt = await tx.wait();
        
    if(receipt.status == 1){  
      setIsProcessing(false);
      dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 
    }else{
       setErrorMessage('Sorry, unable to process at the moment');
        setToggleAlert(true);

        dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
        setIsProcessing(false);
    }


    updateBalance();   


}
catch(err){
console.log(err);
setErrorMessage("Transaction required " + charge + " BNB node fee");
  
setToggleAlert(true);
setIsProcessing(false);

}

              
}



 return (
 
    <>
  
  <div className="roadmap-box right">
                  <div className="time">Sell DGT</div>
                  <div className="content">
                  <h5 className="title">1 BNB = {state.metRate} DGT</h5>
                  <div className="text">

                      <div className="input-group mb-3">
                                              
                          <input type="number" className="form-control" placeholder="20" onChange={(e) => calculateSell(e.target.value)} />
                          
                      </div>
                      <div className='input-group mb-3'>
                        <p>
                          Rate: {numeral(bnbToGet).format("0.0000")} BNB = {numeral(metToSell).format("0.0000")} DGT
                        </p>
                          
                          
                      </div>
                      <div className='input-group mb-3'>
                      
                          <p className='mute'>
                              You will receive { numeral(bnbToGet).format("0.0000") } BNB
                          </p>
                        
                          
                      </div>
                      <div className='input-group mb-3'>
                        <p className='mute'>
                              You will be charge {numeral(sellCharge + 0.015).format("0.0000")} BNB
                        </p>
                      </div>
                    


                      <Myalert message={ErrorMessage} toggle={toggleAlert} />


                      {
                            walletIsConnected != true  ? <>
                            <ConnectWallet inviteBy='1'/>
                            
                          </>:<>
                          
                          {

                                showButton == true ? <>

                                      {
                                          isProcessing == true ? <>

                                                  <Myalert message={serverInfo} toggle={true} />
                                                    

                                          </>: <>
                                              <button className="fs-14 form-control" onClick={() => sellMet()}>Sell DGT</button>

                                          </>
                                      }
                                      
                                  </>: <> </>
                          } 
                          
                          
                          </>
                      }

                        
                  </div>
                  </div>
                </div>
       
    </>
 )



}


export default SellMet;
